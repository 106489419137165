$theme_green : #1dd1a1;
$theme_green_light: #EFFDF5;
$theme_green_hover: #1ea388;
$theme_orange: #ff9800;
$theme_orange_hover: #ff6c00;
$theme_blue: #5372F0;
$theme_blue_hover: #254ded;
$theme_dark_blue: #0E2E50;
$theme_red: #dc3545;
$theme_red_hover: #c12231;
$btn_border: #706f89;
$btn_border_hover: #706f89;
$text_color: #5a5a5a;
$text_light: #a5a5a5;
$white_color: #fff;
$black_color: #000;
$bg_color: #f8f8f8;
$border_color: #dee2e6;
@import "./app-config.scss";

* {
    font-size: 14px;
}

.user-list {
    .text-muted {
        font-size: 13px;

        b {
            color: #2a2a2a;
        }
    }

    div.search-group {
        .input-group {
            position: relative;

            .clear-search {
                background-color: transparent;
                position: absolute;
                right: 36px;
                top: 6px;
                width: 30px;
                z-index: 10;
                cursor: pointer;
            }
        }

    }

    div.grid-row {
        min-height: 400px;

        table {
            font-size: 14px;

            tr {
                td {
                    svg.grid-default-pic {
                        font-size: 25px;
                    }
                }
            }

        }
    }

    .pagination {
        li.disabled {
            cursor: not-allowed;

            a {
                border-color: #edf2f9;
                background-color: #edf2f9;
                color: #cacaca;
            }
        }

        li {
            a {
                font-size: 14px;
            }
        }
    }

}

.main_border {
    margin-top: 1px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 15px;
}

.custom_card {
    background-color: $white_color;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
    padding: 10px;
    height: 100%;
}

.admin_btn {
    width: 90px;
    margin: 2px;
    border: 1px solid $border_color !important;
    background-color: $white_color !important;
    color: $text_color !important;

    &:hover {
        background-color: $bg_color !important;
    }
}

.form_box {
    margin-bottom: 10px;

    .row {
        align-items: center;

        .label_box {

            .label {
                margin: 0;
                text-transform: capitalize;
            }
        }

        .value_box {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .value_text {
                margin: 0;
                font-size: 120%;
                color: $text_color;
            }
        }

        .update_value {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .update_btn_box {
                display: flex;
            }
        }
    }
}


.image_label_box {
    margin: 0;

    img {
        cursor: pointer;
    }

    input {
        display: none;
    }
}

.accordion_box {
    .card {
        .card-header {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .label_box {
                .label {
                    text-transform: capitalize;
                }
            }
        }

        .accordion-collapse {
            form {
                .form-label {
                    text-transform: capitalize;
                }
            }
        }
    }

}
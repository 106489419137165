@import "../app-config.scss";

.home_setting {
    margin-top: 1px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 15px;

    .brand_logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .brand_name {
            margin-bottom: 0;
            color: $text_color;
            font-size: 120%;
            font-weight: 500;
        }
    }

    .update_brand {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
            flex-basis: 60%;
        }

        .update_btn_box {
            flex-basis: 33%;

            button {
                &:first-child {
                    margin-right: 3px;
                }
            }
        }
    }


}
.user_img {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 10px 5px;
}

.user_img h5 {
    margin: 10px 0;
}

.user_details {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    height: 100%;
    padding: 10px 20px;
}

.user_details p {
    margin: 10px 0;
}

.user_details .title {
    font-weight: 600;
}